<i18n lang="yaml">
pt:
  archive: 'Arquivo de {pluralizedResource}'
  actions:
    unarchive: 'Desarquivar {singularizedResource}'
    delete: 'Apagar {singularizedResource}'
    rename: 'Renomear {singularizedResource}'
  resources:
    view: 'página | páginas'
    sheet: 'base de dados | bases de dados'
    workflow: 'automação | automações'
en:
  archive: '{capitalizedResourcePlural} archive'
  actions:
    unarchive: 'Unarchive {singularizedResource}'
    delete: 'Remove {singularizedResource}'
    rename: 'Rename {singularizedResource}'
  resources:
    view: 'view | views'
    sheet: 'sheet | sheets'
    workflow: 'workflow | workflows'
</i18n>

<template>
  <v-menu
    transition="fade-transition"
    location="right"
    content-class="archive__menu"
  >
    <template #activator="{ props }">
      <ListItem
        v-bind="props"
        class="v-list-item--link"
        exact-path
        :index="0"
        :item-title-typography="'text-body-2'"
        :item="{
          title: t('archive', { pluralizedResource: t(`resources.${resource}`, 2), capitalizedResourcePlural: _capitalize(t(`resources.${resource}`, 2)) }),
          icon: 'fa-archive fa-regular',
        }"
        left
      />
    </template>

    <ArchivedItemsDrawer :items="archivedItems" />
  </v-menu>
</template>

<script>
import ListItem from '~/components/list/ListItem';
import { mapState } from '~/assets/javascript/modules/vuex';
import { builderMixin } from '~/mixins/builderMixin';
import ArchivedItemsDrawer from './_archived-items-drawer';

export default {
  name: 'Archive',
  components: {
    ListItem,
    ArchivedItemsDrawer,
  },
  mixins: [builderMixin()],
  props: {
    resource: {
      type: String,
      required: true,
      validator: value => ['view', 'sheet', 'workflow'].includes(value),
    },
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('builderView', ['views']),
      ...mapState('workflows', ['workflows']),
      ...mapState('viewOptions', {
        sheets: 'sheetsCache',
      }),
    };
  },
  computed: {
    archivedItems() {
      const resources = this[`${this.resource}s`] || [];

      return resources
        .filter(resource => Boolean(resource.archived_at))
        .map(this.buildArchivedItem)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
  },
  methods: {
    buildArchivedItem(resource) {
      const resourceTypeRouteMap = {
        view: 't-tenantSlug-studio-pages-id',
        sheet: 't-tenantSlug-studio-data-id',
        workflow: 't-tenantSlug-studio-automations-id',
      };
      const title = this.resource === 'view' ? resource.page_name : resource.name;
      const to = this.localePath({ name: resourceTypeRouteMap[this.resource], params: { id: resource.id, tenantSlug: this.$route.params.tenantSlug } });
      const actioni18nParams = { singularizedResource: this.t(`resources.${this.resource}`, 1) };
      const canDeleteResource = this.resource !== 'sheet' || (!resource.read_only && !resource.sheet_protected);

      // checar se !sheet.read_only && !sheet.sheet_protected caso seja sheet e incluir delete nesses casos
      const actions = [
        {
          title: this.t('actions.unarchive', actioni18nParams),
          icon: 'fa-undo fa-regular',
          onClick: () => this.builderMixin__unarchive(resource),
        },
        {
          title: this.t('actions.rename', actioni18nParams),
          icon: 'fa-pencil fa-regular',
          onClick: () => this.builderMixin__rename(resource),
        },
      ];

      if (canDeleteResource) {
        actions.push({
          title: this.t('actions.delete', actioni18nParams),
          icon: 'fa-trash fa-regular',
          onClick: () => this.builderMixin__delete(resource),
        });
      }

      return {
        id: resource.id,
        title,
        to,
        actions,
      };
    },
  },
};
</script>

<style lang="scss">
.archive__menu {
  width: 256px;
  background-color: white;
  position: absolute;
  bottom: 10px;
  top: unset !important;
}
</style>
