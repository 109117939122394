<i18n lang="yaml">
pt:
  editCategories: 'Editar categorias'
  createCategory: 'Criar categoria'
  collapseCategoriesMenu: 'Recolher menu de categorias'
  expandCategoriesMenu: 'Expandir menu de categorias'
  select: 'Selecionar'
en:
  editCategories: 'Edit categories'
  createCategory: 'Create category'
  collapseCategoriesMenu: 'Collapse categories menu'
  expandCategoriesMenu: 'Expand categories menu'
  select: 'Select'
</i18n>

<template>
  <div class="studio-category-dropdown">
    <v-menu
      location="bottom"
      :max-width="230"
      :max-height="500"
    >
      <template #activator="{ props, isActive }">
        <v-chip
          v-bind="props"
          class="align-center v-chip--block d-flex"
          variant="outlined"
          label
          :aria-label="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
        >
          <v-icon
            class="mr-2 ml-0"
            size="small"
            start
          >
            {{ currentCategory.category_icon }}
          </v-icon>

          <span class="text-truncate d-flex">
            {{ currentCategory.category_name }}
          </span>

          <v-spacer />

          <v-icon
            end
            size="small"
            :title="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
            :aria-label="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
          >
            far {{ isActive ? 'fa-caret-up' : 'fa-caret-down' }}
          </v-icon>
        </v-chip>
      </template>

      <v-list density="compact">
        <v-list
          v-if="categoryHierarchy.length"
          :selected="currentCategoryName"
          density="compact"
          @update:selected="selectedCategoryChanged($event[0])"
        >
          <v-list-item
            v-for="({ category_icon, category_name }, index) in categoryHierarchy"
            :key="index"
            :value="category_name"
            :active="category_name === currentCategoryName"
            :aria-label="`${t('select')} ${category_name}`"
            density="compact"
          >
            <template #prepend>
              <v-icon
                start
                size="small"
              >
                {{ category_icon }}
              </v-icon>
            </template>

            <div
              class="text-body-2 text-truncate"
              :title="category_name"
            >
              {{ category_name }}
            </div>
          </v-list-item>
        </v-list>

        <v-divider class="my-2" />

        <v-list-item
          density="compact"
          @click="openDialogEditCategories"
        >
          <template #prepend>
            <v-icon
              start
              size="small"
            >
              fa-pen fa-regular
            </v-icon>
          </template>

          <div
            class="text-body-2 text-truncate"
            :title="t('editCategories')"
          >
            {{ t('editCategories') }}
          </div>
        </v-list-item>

        <v-list-item
          density="compact"
          @click="openDialogCategoryCreation"
        >
          <template #prepend>
            <v-icon
              start
              size="small"
            >
              fa-plus fa-regular
            </v-icon>
          </template>

          <div
            class="text-body-2 text-truncate"
            :title="t('createCategory')"
          >
            {{ t('createCategory') }}
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import ROUTES from '~/assets/javascript/constants/routes';
import { mapGetters, mapMutations, mapState, mapActions } from '~/assets/javascript/modules/vuex';

export default {
  name: 'StudioCategoryDropdown',
  props: {
    resource: { type: String, required: true, validator: value => ['view', 'sheet', 'workflow'].includes(value) },
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('workspace', ['currentCategoryName']),
      ...mapState('builderView', ['views']),
      ...mapGetters('workspace', ['categoryHierarchy', 'firstWorkflowId']),
      ...mapActions('workspace', ['firstViewIdFromViews']),
      ...mapMutations('workspace', ['setCurrentCategoryName']),
      ...mapMutations('dialog', ['openDialog']),
    };
  },
  computed: {
    currentCategory() {
      if ((this.categoryHierarchy || []).length === 0) return {};

      return this.categoryHierarchy.find(({ category_name: name }) => name === this.currentCategoryName) || {};
    },
  },
  methods: {
    async selectedCategoryChanged(categoryName) {
      this.setCurrentCategoryName(categoryName);

      switch (this.resource) {
        case 'view': {
          const newRouteParams = {
            name: ROUTES.studio.views.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
            },
          };

          const firstViewId = await this.firstViewIdFromViews(this.views);

          if (firstViewId) {
            newRouteParams.name = ROUTES.studio.view.routeName;
            newRouteParams.params.id = firstViewId;
          }

          this.$router.push(this.localePath(newRouteParams));
          break;
        }

        case 'workflow': {
          const newRouteParams = {
            name: ROUTES.studio.workflows.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
            },
          };

          if (this.firstWorkflowId) {
            newRouteParams.name = ROUTES.studio.workflow.routeName;
            newRouteParams.params.id = this.firstWorkflowId;
          }

          this.$router.push(this.localePath(newRouteParams));
          break;
        }

        default: {
          break;
        }
      }
    },
    openDialogCategoryCreation() {
      this.openDialog({
        component: 'DialogCreateOrEditCategory',
        attrs: {},
        on: {
          onCreate: (categoryName) => {
            this.selectedCategoryChanged(categoryName);
          },
        },
      });
    },
    openDialogEditCategories() {
      this.openDialog({
        component: 'DialogEditCategories',
        attrs: {},
      });
    },
  },
};
</script>

<style lang="scss">
.studio-category-dropdown {
  & .v-chip__content {
    width: 100%;
  }
}
</style>
