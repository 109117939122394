<template>
  <div class="resize-handler__wrapper">
    <v-expand-x-transition>
      <div
        v-show="!hide"
        v-test-id="'slot-wrapper'"
        :class="slotClass"
        :style="{ width }"
      >
        <slot />
      </div>
    </v-expand-x-transition>

    <div
      v-test-id="'toggle-wrapper'"
      :class="`resize-handler__toggle-wrapper resize-handler__toggle-wrapper--${side}`"
    >
      <div
        v-test-id="'toggle'"
        :class="`resize-handler__toggle resize-handler__toggle--${side}`"
        @click="toggle"
      >
        <v-icon
          v-test-id="'toggle-icon'"
          size="x-small"
          class="px-"
          color="white"
          :class="`resize-handler__toggle-icon--${side}`"
        >
          far {{ toggleIcon }}
        </v-icon>
      </div>
    </div>

    <div
      ref="dragHandler"
      v-test-id="'drag-handler'"
      class="resize-handler__handler"
      :class="{
        'resize-handler__handler--active': isDragging,
        'resize-handler__handler--hidden': hide,
        [`resize-handler__handler--${side}`]: true,
      }"
      :style="{ [reverseSide]: hide ? '-7px' : '-2px' }"
      @mousedown="setDrag(true)"
      @touchstart="setDrag(true)"
    />
  </div>
</template>

<script>

export default {
  name: 'ResizeHandler',
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/no-unused-properties
    right: {
      type: Boolean,
      default: false,
    },
    defaultWidth: {
      type: Number,
      required: true,
    },
    slotClass: {
      type: String,
      default: '',
    },
    resourceKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDragging: false,
      width: this.defaultWidth,
      hide: false,
    };
  },
  computed: {
    side() {
      return this.left ? 'left' : 'right';
    },
    reverseSide() {
      return this.left ? 'right' : 'left';
    },
    toggleIcon() {
      if (this.left) {
        return this.hide ? 'fa-arrow-right-from-line' : 'fa-arrow-left-from-line';
      }

      return this.hide ? 'fa-arrow-left-from-line' : 'fa-arrow-right-from-line';
    },
    storageValues() {
      const storage = JSON.parse(localStorage?.getItem('resize-handler') || '{}');

      return storage[this.resourceKey]?.[this.side] || {
        width: this.defaultWidth,
        hide: false,
      };
    },
  },
  watch: {
    width(newWidth) {
      this.setStorageValue({ width: parseInt(newWidth, 10) });
    },
    hide(newHide) {
      this.setStorageValue({ hide: newHide });
    },
  },
  beforeMount() {
    this.width = `${this.storageValues.width}px`;
    this.hide = this.storageValues.hide;
  },
  mounted() {
    window.addEventListener('mousemove', this.dragging);
    window.addEventListener('touchmove', this.dragging);
    window.addEventListener('mouseup', this.stopDragging);
    window.addEventListener('touchend', this.stopDragging);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.dragging);
    window.removeEventListener('touchmove', this.dragging);
    window.removeEventListener('mouseup', this.stopDragging);
    window.removeEventListener('touchend', this.stopDragging);
  },
  methods: {
    stopDragging() {
      if (this.isDragging) this.setDrag(false);
    },
    dragging(event) {
      if (this.isDragging) {
        const { clientX } = event;

        const width = this.left ? clientX : window.innerWidth - clientX;

        if (width >= 40) {
          this.width = `${width}px`;
          this.hide = false;
        } else {
          this.hide = true;
        }
      }
    },
    toggle() {
      this.hide = !this.hide;

      if (!this.hide && parseInt(this.width, 10) === 0) {
        this.width = `${this.defaultWidth}px`;
      }
    },
    setDrag(value) {
      const html = document.getElementsByTagName('html').item(0);
      html.classList.toggle('resize-handler__grabbing', value);
      this.isDragging = value;
    },
    setStorageValue(value) {
      const newValue = { ...this.storageValues, ...value };
      const storage = JSON.parse(localStorage?.getItem('resize-handler') || '{}');

      if (_isEqual(storage[this.resourceKey]?.[this.side], newValue)) return;

      localStorage?.setItem('resize-handler', JSON.stringify({
        ...storage,
        [this.resourceKey]: {
          ...storage[this.resourceKey],
          [this.side]: newValue,
        },
      }));
    },
  },
};
</script>

<style lang="scss">
.resize-handler__wrapper {
  position: relative;
}

.resize-handler__toggle-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.resize-handler__toggle-wrapper--right {
  left: -3px;
  direction: rtl;
}

.resize-handler__toggle-wrapper--left {
  right: -3px;
}

.resize-handler__toggle {
  opacity: 0.7;
  background-color: var(--z-color-shade-light-4);
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.resize-handler__toggle--left {
  border-radius: 0 0 50% 0;
}

.resize-handler__toggle--right {
  border-radius: 0 0 0 50%,
}

.resize-handler__toggle-icon--left {
  transform: rotate(45deg);
}

.resize-handler__toggle-icon--right {
  transform: rotate(-45deg);
}

.resize-handler__handler {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  z-index: 1;
  border-radius: 2px;
  user-select: none;

  &--active {
    cursor: col-resize;

  }

  &--active, &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.resize-handler__handler--hidden {
  width: 7px;
}

.resize-handler__handler--hidden.resize-handler__handler--right {
  cursor: w-resize;
}

.resize-handler__handler--hidden.resize-handler__handler--left {
  cursor: e-resize;
}

.resize-handler__grabbing * {
  cursor: col-resize;
}
</style>
