<i18n lang="yaml">
pt:
  findInArchive: 'Encontrar no arquivo'
en:
  findInArchive: 'Find in archive'
</i18n>

<template>
  <div class="pa-4">
    <deck-text-field
      v-model="searchTerm"
      class="archived-items-drawer__search-input"
      size="medium"
      prepend-icon="fa-search fa-regular"
      hide-details
      autofocus
      :aria-label="t('findInArchive')"
      :placeholder="t('findInArchive')"
      @focus="cleanSearchTerm"
    />
    <div class="archived-items-drawer__items-list">
      <ListItem
        v-for="(item, index) in filteredItems"
        :key="item.id"
        :index="index"
        left
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import ListItem from '~/components/list/ListItem';
import { normalizedSearch } from '~/assets/javascript/utils';

export default {
  name: 'ArchivedItemsDrawer',
  components: {
    ListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    filteredItems() {
      return this.searchTerm ? normalizedSearch(this.searchTerm, this.items) : this.items;
    },
  },
  methods: {
    cleanSearchTerm() {
      this.searchTerm = '';
    },
  },
};
</script>

<style lang="scss">
$search-icon-size: 14px;
.archived-items-drawer__items-list {
  max-height: 400px;
  overflow-y: auto;
}

.archived-items-drawer__search-input {
  font-size: 13px;

  .v-input__prepend-inner {
    align-self: center !important;
    margin: 0 !important;

    .v-input__icon {
      min-width: $search-icon-size;
      width: $search-icon-size;
    }

    .v-icon {
      font-size: $search-icon-size;
    }
  }
}
</style>
